import React from 'react';

import Layout from "../../components/layout";

export default () => (
  <Layout>
    <p>
      My name is Sajjad Heydari, I'm a Computer Science grad student at University of Manitoba, Complex Data Lab.
    </p>
    <p>
    My research interest lies in the area of Neural Networks and Machine Learning and their applications. I've conducted research on the following topics:
    </p>
    <ul>
      <li>Natural Language Processing</li>
      <li>Cellular Automata</li>
      <li>Graph Neural Networks</li>
    </ul>
  </Layout>
);
